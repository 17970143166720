import React from 'react'
import NavBar from './components/NavBar/NavBar'
import Header from './components/header/Header'
import About from './components/about/About'
import Work from './components/work/Work'
import Skill from './components/skills/Skill'
import Testimonial from './components/testimonial/Testimonial'
import Footer from './components/footer/Footer'

const Home = () => {
    return (
        <>
            <NavBar />
            <div className="app___container">
                <div>
                    <Header />
                    <About />
                    <Work />
                    <Skill />
                    <Testimonial />
                    <Footer />
                </div>
            </div>

        </>
    )
}

export default Home