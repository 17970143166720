import { HiMenuAlt4, HiX } from "react-icons/hi";
import { FiSearch } from "react-icons/fi";
import { MdKeyboardBackspace } from "react-icons/md";
import { GrClose } from "react-icons/gr";
import "./navBar.scss";
import { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Context } from "../../context/Context";

const NavBar = ({ projects }) => {
  const [toggle, setToggle] = useState(false);
  const [searchMobile, setSearchMobile] = useState(false);
  const searchInput = useRef(null);
  const context = useContext(Context);
  const { works, setFilterWork } = context;
  const [isDownloading, setIsDownloading] = useState(false);

  useEffect(() => {
    searchMobile && searchInput.current.focus();
  }, [searchMobile]);
  const handleChange = (e) => {
    setFilterWork(
      works.filter((w) =>
        w.title.toUpperCase().match(e.target.value.toUpperCase())
      )
    );
  };

  const downloadFile = () => {
    setIsDownloading(true);
    const link = document.createElement("a");
    const baseUrl = new URL(window.location.href).origin;
    link.href = `${baseUrl}/resume.pdf`;

    link.download = "Muneeb_Rana_Resume.pdf";

    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
    setIsDownloading(false);
  };

  return (
    <nav className="app__navbar">
      <div className="app__navbar-logo">
        {projects && (
          <div className="back">
            <Link to={"/"} className="link">
              <MdKeyboardBackspace />
            </Link>
          </div>
        )}
        <div className="border">
          <img src={"/muneeb2_xs.png"} alt="muneeb" />
        </div>
        <h3 id="name__logo">
          <b>M</b>unee<b id="b2">b</b>
        </h3>
      </div>
      {!projects && (
        <ul className="app__navbar-links">
          {["home", "about", "work", "skills", "testimonial", "contact"].map(
            (item) => (
              <li className="app__flex p-text" key={`link-${item}`}>
                <div />
                <a href={`#${item}`}>{item}</a>
              </li>
            )
          )}
        </ul>
      )}
      <div className="btn-container">
        {!projects && (
          <div className="projects">
            <Link to="/projects" className="button p-text">
              Projects
            </Link>
          </div>
        )}
        <div className="projects projects-fill">
          <button
            disabled={isDownloading}
            onClick={downloadFile}
            className="button p-text"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              width={"21"}
              height={"21"}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3"
              />
            </svg>
            <span className="text">Download Resume</span>
          </button>
        </div>
        {projects && (
          <div
            className={
              searchMobile ? "search__bar mobile__searchbar" : "search__bar"
            }
          >
            {searchMobile ? (
              <GrClose
                onClick={() => {
                  setSearchMobile(false);
                  searchInput.current.value = "";
                }}
              />
            ) : (
              <FiSearch onClick={() => setSearchMobile(!searchMobile)} />
            )}
            <input
              ref={searchInput}
              type="text"
              onChange={handleChange}
              className={searchMobile ? "input mobile__bar" : "input"}
              placeholder="Search project"
            />
          </div>
        )}

        {!projects && (
          <div className="app__menu">
            <HiMenuAlt4 onClick={() => setToggle(true)} />
            <div className={toggle ? "mobile-menu" : ""}>
              <HiX onClick={() => setToggle(false)} />
              <ul>
                {[
                  "home",
                  "about",
                  "work",
                  "skills",
                  "testimonial",
                  "contact",
                ].map((item) => (
                  <li key={item}>
                    <a href={`#${item}`} onClick={() => setToggle(false)}>
                      {item}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};

export default NavBar;
