import { useContext, useEffect, useState } from "react";
import NavBar from "../NavBar/NavBar";
import { motion } from "framer-motion";
import "./projects.scss";
import { urlFor } from "../../client";
import { AiFillEye, AiFillGithub } from "react-icons/ai";
import { Context } from "../../context/Context";
import { useLocation } from "react-router-dom";

const ScrollToTop = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top whenever route changes
  }, [location.pathname]);

  return null;
};

function Projects() {
  const [activeFilter, setActiveFilter] = useState("All");
  const [animateCard, setAnimateCard] = useState({ y: 0, opacity: [0, 1] });
  const context = useContext(Context);
  const { works, filterWork, setFilterWork } = context;

  const handelWorkFilter = (item) => {
    setActiveFilter(item);
    setAnimateCard({ y: 100, opacity: 0 });
    setTimeout(() => {
      setAnimateCard({ y: 0, opacity: 1 });
      if (item === "All") {
        setFilterWork(works);
      } else {
        setFilterWork(works.filter((work) => work.tags.includes(item)));
      }
    }, 500);
  };

  // const { pathname } = useLocation();

  // useEffect(() => {
  //   window.scrollTo(0, 0); // Scroll to top
  // }, [pathname]);

  return (
    <div className="app">
      <ScrollToTop />
      <NavBar projects={true} />
      <div className="app__work-filter">
        {["All", "React Js", "React Native", "Next js", "UI/UX", "GraphQL"].map(
          (cat, index) => (
            <div
              key={cat + index}
              onClick={() => handelWorkFilter(cat)}
              className={`app__work-filter-item app__flex p-text ${
                activeFilter === cat ? "item__active" : ""
              }`}
            >
              {cat}
            </div>
          )
        )}
      </div>
      <motion.div
        animate={animateCard}
        transition={{ duration: 0.5, delayChildren: 0.5 }}
        className="app__work-portfolio"
        style={{ minHeight: "81vh" }}
      >
        {filterWork.map((work, index) => (
          <div className="app__work-item" key={index}>
            <div className="app__work-img app__flex">
              <img src={urlFor(work.imgUrl)} alt={work.name} />
              <motion.div
                whileHover={{ opacity: [0, 1] }}
                transition={{ duration: 0.1, ease: "easeInOut" }}
                className="app__work-hover app__flex"
              >
                <a href={work.projectLink} rel="noreferrer" target="_blank">
                  <motion.div
                    whileHover={{ scale: 1.2 }}
                    transition={{ duration: 0.25, ease: "easeInOut" }}
                    className="app__flex"
                  >
                    <AiFillEye />
                  </motion.div>
                </a>

                <a href={work.codeLink} rel="noreferrer" target="_blank">
                  <motion.div
                    whileHover={{ scale: 1.2 }}
                    transition={{ duration: 0.25, ease: "easeInOut" }}
                    className="app__flex"
                  >
                    <AiFillGithub />
                  </motion.div>
                </a>
              </motion.div>
            </div>
            <div className="app__work-content app__flex">
              <h4 className="bold-text">{work.title}</h4>
              <p className="p-text">
                {work.description.substring(0, 102)}
                {work.description.length > 102 && " . . ."}
              </p>
              <div className="app__work-tag app__flex">
                <p className="p-text">{work.tags[0]}</p>
              </div>
            </div>
          </div>
        ))}
      </motion.div>
    </div>
  );
}

export default Projects;
