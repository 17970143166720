import "./about.scss"
import { motion } from "framer-motion"
import { useEffect, useState } from "react"
import { urlFor, client } from "../../client"
import { AppWrappper } from "../../wrapper/AppWrappper"
import MotionWrapper from "../../wrapper/MotionWrapper"

const About = () => {
   const [abouts, setAbouts] = useState([])

   useEffect(() => {
     const query = '*[_type == "abouts"]';

     client.fetch(query).then((data)=> setAbouts(data))
   }, [])
   console.table(abouts)
   
  return (
    <div className="app__about">
      <h2 className="head-text">
        I know that 
        <span> Good Development</span>
        <br/>
        means
        <span> Good Business</span>
        </h2>
      <div className="app__profiles">
          {abouts.map((about, index)=>(
              <>
               {about.imgUrl.asset && <motion.div 
                whileInView={{opacity: [0, 1] }}
                whileHover={{scale: 1.1}}
                transition={{ duration: 0.5, type: "tween" }}
                className="profile__Item"
                key={about.title+index}
                >
                <img src={urlFor(about.imgUrl)} alt="about_img" />
                <h2 className="bold-text" style={{marginTop: 20}}>{about.title}</h2>
                <p className="p-text" style={{marginTop: 10}}>{about.description}</p>
              </motion.div>}
              </>
            )
           )}
      </div>
    </div>
  )
}

export default MotionWrapper(AppWrappper(About, "about"), "app__whitebg")