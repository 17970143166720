import "./header.scss"
import { motion } from "framer-motion"
import { images } from "../../constants"
import { AppWrappper } from "../../wrapper/AppWrappper"

const Header = () => {
    const scaleVariants = {
        whileInView: {
            scale: [0, 1],
            opacity: [0, 1],
            transition: {
                duration: 0.5,
                ease: "easeInOut"
            }
        }
    }
  return (
       <div id="home" className="app__header" >
           <motion.div
             whileInView={{ x:[-100, 0], opacity: [0, 1]}}
             transition={{ duration: 0.5 }}
             className="app__header-info"
           >
               <div className="app__header-badge">
                   <div className="badge-cmp app__flex">
                       <span>👋</span>
                       <div style={{ marginLeft: 20}}>
                        <p className="p-text">Hello, I am</p>
                        <h1 className="head-text">Muneeb Rana</h1>
                       </div>
                   </div>
                    <div className="app__flex tags">
                        <p className="p-text">Full Stack Developer</p>
                        <p className="p-text">Freelancer</p>
                    </div>
               </div>
           </motion.div>
           <motion.div
             whileInView={{ opacity: [0, 1] }}
             transition={{ duration: 0.5, delayChildren: 0.5}}
             className="app__header-img"
           >
             <img src={'/muneeb2.png'} alt="profile_bg" />
             <motion.img
                //  whileInView={{ scale: [0, 1], opacity: [0,1] }}
                //  transition={{ duration: 0.8 }}
                animate={{ rotate: 180 }}
                transition={{ type: 'spring', bounce: 0.25}}
                 className="overlay_circle"
                 src={images.circle}
                 alt="Profile_Circcle"
             />
           </motion.div>
           <motion.div
             variants={scaleVariants}
             whileInView={scaleVariants.whileInView}
             className="app__header-circle"
           >
               {[images.react, images.tailwind, images.redux, images.node, images.javascript, images.css, images.sass].map((item, index)=>(
                   <div className="circle-cmp app__flex" key={index}>
                        <img src={item} alt="circles" />
                   </div>
               ))}
           </motion.div>
       </div>
    )
}

export default AppWrappper(Header, "home")