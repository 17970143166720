import React from 'react'

const NavigationDots = ({active}) => {
  return (
    <div className='app__navigation'>
        {["home", "about", "work", "skills", "testimonial", "contact"].map((item, index)=>(
            <a 
              href={`#${item}`}
              className="app__navigation-dot"
              key={index}
              style={active === item ? { background: "#313BAC" } : {} }
            />
        ))}
    </div>
  )
}

export default NavigationDots