import React from 'react'
import NavigationDots from '../components/NavigationDots'
import SocialMedia from '../components/SocialMedia'

export const AppWrappper = (Component, idName) => function HOC (){
  return (
    <div id={idName} className={`app__container scroll-item`}>
        <SocialMedia />
        <div className="app__wrapper app__flex">
            <Component />
        </div>
        <NavigationDots active={idName}/>
    </div>
  )
}
