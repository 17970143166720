import "./footer.scss"
import { AppWrappper } from "../../wrapper/AppWrappper"
import MotionWrapper from "../../wrapper/MotionWrapper"
import { useState } from "react"
import { client } from "../../client"
import { images } from "../../constants"

const Footer = () => {
  const [formData, setFormData] = useState({ name: "", email: "", message: ""})
  const [isFormSubmitted, setIsFormSubmitted] = useState(false)
  const [loading, setLoading] = useState(false)
  const { name, email, message } = formData

  const handleChange = (e)=>{
    setFormData({ ...formData, [e.target.name]: e.target.value})
  }

  const handleSubmit = ()=>{
    setLoading(true)
    const contact = {
      _type: 'contact',
      name: name,
      email: email,
      message: message
    }
    client.create(contact)
      .then(()=>{
        setLoading(false)
        setIsFormSubmitted(true)
      })
  }
  return (
    <>
      <h2 className="head-text">Take a <span> coffee </span> & <span> chat </span> with me</h2> 
      <div className="app__footer-cards">
        <div className="app__footer-card">
          <img src={images.email} alt="email" />
          <a href="mailto:muneebjs.css@gmail.com" className="p-text">muneebjs.css@gmail.com</a>
        </div>
        <div className="app__footer-card">
          <img src={images.mobile} alt="mobile" />
          <a href="tel: +92 3007171874" className="p-text">+92 3484616075</a>
        </div>
      </div>
     {!isFormSubmitted ? <div className="app__footer-form app__flex">
        <div className="app__flex">
          <input type="text"  className="p-text" placeholder="Your Name" name="name" onChange={handleChange} value={name}/>
        </div>
        <div className="app__flex">
          <input type="text"  className="p-text" placeholder="Your Email" name="email" onChange={handleChange} value={email}/>
        </div>
        <div>
          <textarea className="p-text" placeholder="Your Message" value={message} onChange={handleChange} name="message" />
        </div>
        <button type="button" className="p-text" onClick={handleSubmit}>{ loading ? "loading..." : "Send Message" }</button>
      </div> : <div>
           <h3 className="head-text">Thank you for getting in touch!</h3>
        </div>}
    </>
  )
}

export default MotionWrapper(AppWrappper(Footer, "contact"), "app__whitebg")