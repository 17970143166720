import { motion } from 'framer-motion'
import React from 'react'

const MotionWrapper = (Component, className) => function HOC () {
  return (
    <motion.div
      whileInView={{ opacity: [0, 0, 1], y: [100, 50, 0]}}
      transition={{ duration: 0.5 }}
      className={`${className} app__flex`}
    >
      <Component />
    </motion.div>
  )
}

export default MotionWrapper