import { createContext, useEffect, useState } from "react";
import { client } from "../client";

export const Context = createContext()

export function ContextProvider ({children}){
    const [works, setWorks] = useState([])
    const [filterWork, setFilterWork] = useState([])

    useEffect(() => {
        const query = '*[_type == "works"]';

        client.fetch(query).then((data)=>{
          setWorks(data);
          setFilterWork(data)
        })
    }, [])
    return(
        <Context.Provider value={{ works, setWorks, filterWork, setFilterWork }}>
         {children}
    </Context.Provider>
    )
}