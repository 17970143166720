import { useState, useEffect } from "react"
import "./skill.scss"
import { motion } from "framer-motion"
import { urlFor, client } from "../../client"
import { AppWrappper } from "../../wrapper/AppWrappper"
import ReactToolTip from "react-tooltip"
import MotionWrapper from "../../wrapper/MotionWrapper"
const Skill = () => {
    const [experiences, setExperiences] = useState([])
    const [skills, setSkills] = useState([])

    useEffect(() => {
        const query = '*[_type == "experiences"]';
        const skillsQuery = '*[_type == "skills"]';

        client.fetch(query).then((data)=>{
          setExperiences(data);
        })
        client.fetch(skillsQuery).then((data)=>{
            setSkills(data);
          })
    }, [])
  return (
    <>
      <h2 className="head-text">Skills <span> & </span> Experience</h2>
    <div className="app__skills-container">
        <motion.div
          className="app__skills-list"
        >
          {skills.map((skill, index)=>(
             <motion.div
               whileInView={{ opacity: [0, 1]}}
               transition={{duration: 0.5}}
               className="app__skills-item app__flex"
               key={skill.name+index}
             > 
             <div className="app__flex" style={{ backgroundColor: skill.bgColor}}>
                 <img src={urlFor(skill.icon)} alt={skill.name} />
             </div>
             <p className="p-text">{skill.name}</p>
            </motion.div>
          ))}
        </motion.div>
        <motion.div className="app__skills-exp">
            {experiences?.map((experience, index)=>(
              <motion.div className="app__skills-exp-item" key={experience.year+index}>
                  <div className="app__skills-exp-year">
                    <p className="bold-text">{experience.year}</p>
                  </div>
                  <motion.div className="app__skills-exp-works">
                      {experience.works.map((work, index)=>(
                        <div key={work.name+index}>
                        <motion.div
                          whileInView={{ opacity: [0, 1]}}
                          transition={{duration: 0.5}}
                          className="app__skills-exp-work"
                          data-tip
                          data-for={work.name}
                        >
                          <h4 className="bold-text">{work.name}</h4>
                          <p className="p-text">{work.company}</p>
                        </motion.div>
                        <ReactToolTip
                          id={work.name}
                          effect="solid"
                          arrowColor="#fff"
                          className="skills-tooltip"
                        >
                          {work.desc}
                        </ReactToolTip>
                      </div>
                      ))}
                  </motion.div>
              </motion.div>
            ))}
        </motion.div>
    </div>
    </>
  )
}

export default MotionWrapper(AppWrappper(Skill, "skills"), "app__whitebg")