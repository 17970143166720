import React from "react";
import { BsInstagram } from "react-icons/bs";
import { IoLogoGithub } from "react-icons/io5";

const SocialMedia = () => {
  return (
    <div className="app__social">
      <a href="https://github.com/muneeb-r" target="_blank" rel="noreferrer">
        <div>
          <IoLogoGithub />
        </div>
      </a>
      <a
        href="https://www.instagram.com/thisismuneebrana/"
        target="_blank"
        rel="noreferrer"
      >
        <div>
          <BsInstagram />
        </div>
      </a>
    </div>
  );
};

export default SocialMedia;
