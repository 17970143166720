import sanityClient from "@sanity/client"
import imageUrlBuilder from "@sanity/image-url"

export const client = sanityClient({
    projectId: "7hb8oae7",
    dataset: "mr_portfolio",
    apiVersion: "2022-02-19",
    useCdn: true,
    token: "skg9PsPL0LBEkvWkjTnbQP1habce58ERHRkU7PG6MS7BQQdcuwL4vxJY8AkTlB58kq4x0Qrz9rJ8Dj3S0mhojnQzPBNrgu2l76cg07m3uigHjInmyPcwVQml2gfWvJkrHpSvosJzm0aGwMDrgMamxhWrsI0WYiNeFyL2Pi6HiFRIiBGIXFM3",
})
const builder = imageUrlBuilder(client)

export const urlFor = (source) => builder.image(source)