import "./testimonial.scss"
import { motion } from "framer-motion"
import { urlFor, client } from "../../client"
import { HiChevronLeft, HiChevronRight } from "react-icons/hi"
import { AppWrappper } from "../../wrapper/AppWrappper"
import MotionWrapper from "../../wrapper/MotionWrapper"
import { useEffect, useState } from "react"

const Testimonial = () => {
    const [brands, setBrands] = useState([])
    const [testimonial, setTestimonial] = useState([])
    const [currentIndex, setCurrentIndex] = useState(0)

    useEffect(() => {
        const query = '*[_type == "testimonials"]';
        const brandsQuery = '*[_type == "brands"]';

        client.fetch(query).then((data)=>{
          setTestimonial(data);
        })
        client.fetch(brandsQuery).then((data)=>{
            setBrands(data);
          })
    }, [])

    const handleClick = (index)=>{
        setCurrentIndex(index)
    }
    
    const tests = testimonial[currentIndex]
  return (
    <>
      {testimonial.length && (
          <>
            <div className="app__testimonial-item">
                <div className="app__testimonial-content">
                    <div>
                      <img src={urlFor(tests.imgurl)} alt="testimonial" />
                    </div>
                    <span>
                        <h4 className="bold-text">{tests.name}</h4>
                        <h5 className="p-text">{tests.company}</h5>
                    </span>
                </div>
                  <p className="p-text">{tests.feedback}</p>
            </div>
            <div className="app__testimonial-btns app__flex">
                <div className="app__flex" onClick={()=> handleClick(currentIndex === 0 ? testimonial.length - 1 : currentIndex - 1)}>
                   <HiChevronLeft />
                </div>
                <div className="app__flex" onClick={()=> handleClick(currentIndex + 1 === testimonial.length ? 0 : currentIndex + 1)}>
                   <HiChevronRight />
                </div>
            </div>
          </>
      )}  
      <div className="app__testimonial-brands app__flex">
          {brands.map((brand)=>(
            <motion.div
            whileInView={{opacity: [0, 1]}}
            transition={{duration: 0.5, type: "tween"}}
            key={brand._id}
            >
              <img src={urlFor(brand.imgUrl)} alt={brand.name} />
            </motion.div>
          ))}
      </div>
    </>
  )
}

export default MotionWrapper(AppWrappper(Testimonial, "testimonial"))